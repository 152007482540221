import React, {useEffect} from 'react';
import ModelUploadPageHeader from '../common/ModelUploadPageHeader';
import ModelUploadFooter from '../common/ModelUploadFooter';
import ModelEditPermissionsForm, {
  ModelEditPermissionsFormData,
} from '../../model/common/ModelEdit/ModelEditPermissons/ModelEditPermissionsForm';
import {isModelWeb} from '../../../../store/model';
import {trackPermissionsTabEvent} from '../../../../tracking/tracking';

type StepPermissionsProps = {
  permissions: ModelEditPermissionsFormData;
  onSubmit: (permissions: ModelEditPermissionsFormData) => void;
  onChange: (permissions: ModelEditPermissionsFormData) => void;
};

const footerComp = () => {
  return <ModelUploadFooter />;
};

const StepPermissions: React.FC<StepPermissionsProps> = ({onSubmit, onChange, permissions}) => {
  const model = permissions.model;
  const webModel = isModelWeb(model);

  useEffect(() => {
    trackPermissionsTabEvent();
  }, []);

  return (
    <div>
      <ModelUploadPageHeader
        title="Setup Model Owners and Viewers"
        subTitle="You are automatically set as model owner."
      />

      <div className="whiteTheme" style={{maxWidth: '80rem', margin: '0 auto'}}>
        <ModelEditPermissionsForm
          onSubmit={onSubmit}
          onChange={onChange}
          data={permissions}
          webModel={webModel}
          buttons={footerComp}
          model={model}
        />
      </div>
    </div>
  );
};

export default StepPermissions;
