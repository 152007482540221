import {UsersApi, UserType, ExternalUserRegistrationDTO, ExternalUserSelfRegistrationDTO} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';

const userApi = new UsersApi(configuration);

//const externalUserAPI = new DefaultApi(configuration);

export const requestUserDetail = (userId: string) => userApi.getUserByIsid({userId});
export const requestUserList = () => userApi.getUserList();
export const requestExternalUserList = () => userApi.getExternalUserList();
export const requestFindUsers = (
  search: string,
  options?: {limit?: number; includeNpa?: boolean; includeUnenrolled?: boolean},
) =>
  userApi.findUsers({
    name: search,
    limit: options?.limit ?? 10,
    includeNpa: options?.includeNpa ?? false,
    includeUnenrolled: options?.includeUnenrolled ?? true,
  });
export const requestUserSetActive = (emailId: string, active: boolean) =>
  userApi.setUserActive({emailId, body: active});
export const requestUserEnroll = (isid: string, userType: UserType) =>
  userApi.addUser({
    newUserDTO: {
      isid,
      userType,
    },
  });
export const requestUserSetUserType = (userId: string, userType: UserType) =>
  userApi.setUserType({
    userId,
    userTypeJSONWrapper: {
      value: userType,
    },
  });

export const requestExternalUserActivate = (email: string) =>
  userApi.setExternalUserActive({
    userReviewDTO: {
      email: email,
    },
  });

export const requestExternalUserDeactivate = (email: string) =>
  userApi.setExternalUserDeactivate({
    userReviewDTO: {
      email: email,
    },
  });

export const requestExternalUserReject = (email: string, reason: string) =>
  userApi.setExternalUserReject({
    rejectRequestDTO: {
      email: email,
      reason: reason,
    },
  });

export const requestExternalUserRegistration = (externalUserRegistration: ExternalUserRegistrationDTO) =>
  userApi.saveExternalUserRegistrationDetails({externalUserRegistrationDTO: externalUserRegistration});

export const requestExternalUserSelfRegistration = (externalUserSelfRegistration: ExternalUserSelfRegistrationDTO) =>
  userApi.externalUserSelfRegistration({externalUserSelfRegistrationDTO: externalUserSelfRegistration});

export const requestExternalUserDetails = (email: string) =>
  userApi.getExternalUserDetails({userReviewDTO: {email: email}});
