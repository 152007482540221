import React, {ReactNode, useCallback, useEffect, useMemo, useRef} from 'react';
import {UserDTO, UserType} from 'hemwb-api';
import {Form, useForm} from '../../../../../core/Form';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from 'react-reactive-form';
import {tid} from '../../../../../../testUtils';
import {Button, Grid, IconButton} from '@material-ui/core';
import AutocompleteUserControl from '../../../../../core/Form/AutocompleteUserControl';
import CloseIcon from '@material-ui/icons/Close';
import GridItem from '../../../../../core/Form/GridItem';
import {UserCard} from '../../../../../core/UserCard/UserCard';
import {useCurrentUser} from '../../../../../../store/auth';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {getModelSteward, modifyModelMetadata} from '../../../../../../store/model';
import {fullName} from '../../../../../../store/user/utils';
import {useDispatch} from 'react-redux';
import {validateModelStewardControl} from './utils';
import {canEditTHModelAccess} from '../../../../../../permissions/usePermissions';
import ActionHeader from '../../../../../core/Header/ActionHeader/ActionHeader';
import {useSideBarModal} from '../../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../../layout/common/SideBarModal';
import AddUserExpertiseLevelForm from './AddUserExpertiseLevelForm';

export type ModelEditPermissionsFormData = {
  model: ModelDTO;
  viewers: UserDTO[];
  displayViewers: UserDTO[];
  owners: UserDTO[];
  calibrationModelers: UserDTO[];
  scenarioModelers: UserDTO[];
  vestLead?: UserDTO;
};

type ModelEditPermissionsFormValues = {
  viewers: UserDTO[];
  displayViewers: UserDTO[];
  owners: UserDTO[];
  calibrationModelers: UserDTO[];
  scenarioModelers: UserDTO[];
  vestLead: UserDTO[];
  modelSteward: UserDTO[];
};

const dataToValues = ({
  model,
  viewers,
  displayViewers,
  owners,
  calibrationModelers,
  scenarioModelers,
  vestLead,
}: ModelEditPermissionsFormData) => ({
  viewers,
  displayViewers,
  owners,
  calibrationModelers,
  scenarioModelers,
  vestLead: [vestLead].filter(Boolean),
  modelSteward: [getModelSteward(model)].filter(Boolean),
});

const valuesToData = (
  {
    owners,
    calibrationModelers,
    scenarioModelers,
    viewers,
    displayViewers,
    vestLead,
    modelSteward,
  }: ModelEditPermissionsFormValues,
  data: ModelEditPermissionsFormData,
): ModelEditPermissionsFormData => {
  return {
    vestLead: vestLead?.[0],
    owners,
    calibrationModelers,
    scenarioModelers,
    viewers,
    displayViewers,
    model: modifyModelMetadata(data.model, {
      isid_of_model_steward: (modelSteward[0] && modelSteward[0].id) || '',
      email_for_model_steward: (modelSteward[0] && modelSteward[0].email) || '',
      name_of_model_steward: (modelSteward[0] && fullName(modelSteward[0])) || '',
    }),
  };
};

type ModelEditPermissionsFormProps = {
  onSubmit: (values: ModelEditPermissionsFormData) => void;
  onChange?: (values: ModelEditPermissionsFormData) => void;
  data: ModelEditPermissionsFormData;
  buttons: (params: FormGroup & {submitting: boolean}) => string | ReactNode;
  model?: ModelDTO;
  pneuInsightsModel?: boolean;
  externalModel?: boolean;
  theoremModel?: boolean;
  webModel?: boolean;
};

const userFindOptions = {limit: 10, includeNpa: true, includeUnenrolled: true};

const ModelEditPermissionsForm: React.FC<ModelEditPermissionsFormProps> = ({
  buttons,
  onSubmit,
  onChange,
  data,
  model,
  externalModel,
  pneuInsightsModel,
  theoremModel,
  webModel,
}) => {
  const {buttonRef, openModal, closeModal, user} = useSideBarModal();
  const dispatch = useDispatch();
  const initialStewardValidation = useRef<Promise<ValidationErrors | null>>();
  const {id: currentUserId} = useCurrentUser();

  const form = useForm(
    new FormGroup({
      viewers: new FormControl([]),
      displayViewers: new FormControl([]),
      calibrationModelers: new FormControl([]),
      scenarioModelers: new FormControl([]),
      owners: new FormControl([], Validators.required),
      vestLead: new FormControl([], Validators.required),
      modelSteward: new FormControl(
        null,
        [
          (control) => {
            const value = control?.value?.[0] as UserDTO;
            return !value || value.id === undefined ? {required: true} : null;
          },
        ],
        (control: AbstractControl) => {
          if (control.dirty) {
            return Promise.resolve(null);
          } else if (initialStewardValidation.current === undefined) {
            initialStewardValidation.current = validateModelStewardControl(control, dispatch);
          }
          return initialStewardValidation.current;
        },
      ),
    }),
    dataToValues(data),
  );

  useEffect(() => {
    if (form) {
      const update = () => setTimeout(() => onChange?.(valuesToData(form.value, data)), 100);
      if (pneuInsightsModel) {
        form.disable();
      }

      const removeUsersFromFieldByField = (removeFromField: string, byField: string) => {
        const removeFromControl = form.get(removeFromField);
        const removeByControl = form.get(byField);
        const idsToRemove = (removeFromControl.value || [])
          .map((u: UserDTO) => u.id)
          .filter((id: UserDTO['id']) => (removeByControl.value || []).some((o: UserDTO) => o.id === id));

        if (idsToRemove.length) {
          removeFromControl.setValue(removeFromControl.value.filter((u: UserDTO) => !idsToRemove.includes(u.id)));
        }
      };

      const handleCalibrationModelersChanged = () => {
        removeUsersFromFieldByField('scenarioModelers', 'calibrationModelers');
      };

      const handleModelStewardChanged = () => {
        removeUsersFromFieldByField('calibrationModelers', 'modelSteward');
        removeUsersFromFieldByField('scenarioModelers', 'modelSteward');
        removeUsersFromFieldByField('viewers', 'modelSteward');
        removeUsersFromFieldByField('displayViewers', 'modelSteward');
      };

      const handleViewersChanged = (user: UserDTO) => {
        if (!user.active) {
          return null;
        }
        removeUsersFromFieldByField('calibrationModelers', 'viewers');
        removeUsersFromFieldByField('scenarioModelers', 'viewers');
        removeUsersFromFieldByField('displayViewers', 'viewers');
      };

      const handleDisplayViewersChanged = () => {
        removeUsersFromFieldByField('calibrationModelers', 'displayViewers');
        removeUsersFromFieldByField('scenarioModelers', 'displayViewers');
        removeUsersFromFieldByField('viewers', 'displayViewers');
      };

      const handleOwnersChanged = () => {
        removeUsersFromFieldByField('calibrationModelers', 'owners');
        removeUsersFromFieldByField('scenarioModelers', 'owners');
        removeUsersFromFieldByField('viewers', 'owners');
        removeUsersFromFieldByField('displayViewers', 'owners');
      };

      const onlyLastVestLead = () => {
        if (form.controls.vestLead.value.length > 1) {
          if (
            form.controls.vestLead.value[form.controls.vestLead.value.length - 1].userType === UserType.EXTERNALUSER
          ) {
            form.controls.vestLead.setValue([form.controls.vestLead.value[0]]);
            dispatch(
              messageAdd('External user cannot be added as the Value and Implementation Lead.', MessageTypes.ERROR),
            );
          } else {
            form.controls.vestLead.setValue([form.controls.vestLead.value[form.controls.vestLead.value.length - 1]]);
          }
        } else {
          if (
            form.controls.vestLead.value.length === 1 &&
            form.controls.vestLead.value[0].userType === UserType.EXTERNALUSER
          ) {
            form.controls.vestLead.setValue([]);
            dispatch(
              messageAdd('External user cannot be added as the Value and Implementation Lead.', MessageTypes.ERROR),
            );
          }
        }
      };
      const onlyLastSteward = () => {
        if (form.controls.modelSteward.value.length > 1) {
          if (
            form.controls.modelSteward.value[form.controls.modelSteward.value.length - 1].userType ===
            UserType.EXTERNALUSER
          ) {
            form.controls.modelSteward.setValue([form.controls.modelSteward.value[0]]);
            dispatch(messageAdd('External user cannot be added as the Model Steward.', MessageTypes.ERROR));
          } else {
            form.controls.modelSteward.setValue([
              form.controls.modelSteward.value[form.controls.modelSteward.value.length - 1],
            ]);
          }
        } else {
          if (
            form.controls.modelSteward.value.length === 1 &&
            form.controls.modelSteward.value[0].userType === UserType.EXTERNALUSER
          ) {
            form.controls.modelSteward.setValue([]);
            dispatch(messageAdd('External user cannot be added as the Model Steward.', MessageTypes.ERROR));
          }
        }
        handleModelStewardChanged();
      };

      form.valueChanges.subscribe(update);

      form.controls.owners.valueChanges.subscribe(handleOwnersChanged);
      form.controls.viewers.valueChanges.subscribe(handleViewersChanged);
      form.controls.displayViewers.valueChanges.subscribe(handleDisplayViewersChanged);
      form.controls.calibrationModelers.valueChanges.subscribe(handleCalibrationModelersChanged);

      form.controls.vestLead.valueChanges.subscribe(onlyLastVestLead);
      form.controls.modelSteward.valueChanges.subscribe(onlyLastSteward);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleSecurityAPIError = useCallback(() => {
    const viewersValue = form.controls.viewers.value;
    viewersValue.pop();
    form.controls.viewers.setValue(viewersValue);
  }, [form]);

  const restrictAccessOfExternalUser = useCallback(
    (name: string) => {
      if (!['modelSteward', 'vestLead'].includes(name)) {
        const roleDetails = form.controls[name].value;
        roleDetails.pop();
        form.controls[name].setValue(roleDetails);
      }
    },
    [form.controls],
  );

  const handleDelete = useCallback(
    (user: UserDTO, formFieldName: string) => {
      const control = form.get(formFieldName);
      control.setValue(control.value.filter((u: UserDTO) => u.id !== user.id));
      control.markAsTouched();
    },
    [form],
  );

  const Modal = useMemo(() => {
    return (
      <SideBarModal
        className="whiteTheme"
        isOpen={!!buttonRef}
        buttonRef={buttonRef}
        closeModal={closeModal}
        height={613}>
        <div style={{width: '60rem'}}>
          {!!buttonRef && user && (
            <AddUserExpertiseLevelForm user={user} isOpen={!!buttonRef} closeModal={closeModal} buttonRef={buttonRef} />
          )}
        </div>
      </SideBarModal>
    );
  }, [buttonRef, closeModal, user]);

  const displayExpertiseLevel = (user: UserDTO) => {
    return webModel ? (
      <>
        <span style={{display: data.model.uuid ? 'inline' : 'none'}}>
          <Button
            variant="outlined"
            color="primary"
            //className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-outlinedSizeSmall MuiButton-sizeSmall"
            onClick={(event) => openModal(event, user)}
            style={{display: 'flow-root'}}>
            Add Expertise
          </Button>

          {Modal}
        </span>
      </>
    ) : (
      <></>
    );
  };

  return (
    <Form
      FieldGroupProps={{strict: false}}
      group={form}
      onSubmit={() => onSubmit(valuesToData(form.value, data))}
      className=""
      {...tid('model-permissions-form')}
      render={(renderParams) => {
        return (
          <>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justify="space-between"
              style={{paddingLeft: '3rem'}}>
              <Grid container item direction="column" sm={12} md={6} style={{paddingRight: '3rem'}}>
                <Grid item>
                  <ActionHeader label={<strong>Provide</strong>}>
                    <strong className="wrapRelative">
                      Value and Implementation Lead{' '}
                      <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">*</span>
                    </strong>
                  </ActionHeader>
                </Grid>
                <GridItem>
                  <AutocompleteUserControl
                    disabled={theoremModel}
                    name="vestLead"
                    label="Find user"
                    userFindOptions={userFindOptions}
                    restrictAccessOfExternalUser={restrictAccessOfExternalUser}
                    model={model}
                    AutocompleteProps={{
                      disableClearable: true,
                      multiple: true,
                      renderTags: () => null,
                      getOptionDisabled: (option) =>
                        form.controls.vestLead.value?.some((u: UserDTO) => u.id === option.id),
                    }}
                  />
                </GridItem>

                <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
                  {form.controls.vestLead.value && form.controls.vestLead.value[0] && (
                    <UserCard
                      style={{marginBottom: '0.8rem'}}
                      label="Value and Implementation Lead"
                      user={form.controls.vestLead.value[0]}
                      key={form.controls.vestLead.value[0].id}
                      actions={
                        !externalModel ? (
                          <>
                            <IconButton
                              style={{float: 'right'}}
                              aria-label="Remove"
                              size="small"
                              onClick={() => {
                                form.controls.vestLead.setValue([]);
                              }}
                              {...tid('vestLead-card-remove', form.controls.vestLead.value[0].id)}>
                              <CloseIcon />
                            </IconButton>
                            {webModel && (
                              <span style={{display: data.model.uuid ? 'inline' : 'none'}}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  //className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-outlinedSizeSmall MuiButton-sizeSmall"
                                  onClick={(event) => openModal(event, form.controls.vestLead.value[0])}
                                  //onSubmit={openModal}
                                  type="button"
                                  style={{display: 'flow-root'}}>
                                  Add Expertise
                                </Button>

                                {Modal}
                              </span>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container item direction="column" sm={12} md={6} style={{paddingRight: '3rem'}}>
                <Grid item>
                  <ActionHeader label={<strong>Provide</strong>}>
                    <strong className="wrapRelative">
                      Model Steward <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">*</span>
                    </strong>
                  </ActionHeader>
                </Grid>
                <GridItem>
                  <AutocompleteUserControl
                    name="modelSteward"
                    label="Find user"
                    restrictAccessOfExternalUser={restrictAccessOfExternalUser}
                    model={model}
                    disabled={theoremModel}
                    AutocompleteProps={{
                      disableClearable: true,
                      multiple: true,
                      renderTags: () => null,
                      getOptionDisabled: (option) =>
                        form.controls.modelSteward.value?.some((u: UserDTO) => u.id === option.id),
                    }}
                  />
                </GridItem>

                <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
                  {form.controls.modelSteward.value && form.controls.modelSteward.value[0] && (
                    <UserCard
                      style={{marginBottom: '0.8rem'}}
                      label="Model Steward"
                      user={form.controls.modelSteward.value[0]}
                      key={form.controls.modelSteward.value[0].id}
                      actions={
                        !externalModel ? (
                          <IconButton
                            aria-label="Remove"
                            size="small"
                            onClick={() => {
                              form.controls.modelSteward.setValue([]);
                            }}
                            {...tid('modelSteward-card-remove', form.controls.modelSteward.value[0].id)}>
                            <CloseIcon />
                          </IconButton>
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container item direction="column" sm={12} md={6}>
                <Grid item>
                  <ActionHeader label="Grant">
                    Add model Owner
                    <br />
                    (Editing capabilities)
                  </ActionHeader>
                </Grid>
                <GridItem>
                  <AutocompleteUserControl
                    name="owners"
                    label="Find User"
                    disabled={theoremModel}
                    userFindOptions={userFindOptions}
                    restrictAccessOfExternalUser={restrictAccessOfExternalUser}
                    model={model}
                    AutocompleteProps={{
                      disableClearable: true,
                      multiple: true,
                      renderTags: () => null,
                      getOptionDisabled: (option) =>
                        form.controls.owners.value?.some((u: UserDTO) => u.id === option.id),
                    }}
                  />
                </GridItem>
                <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
                  {form.controls.owners.value.map((user: UserDTO) => (
                    <UserCard
                      style={{marginBottom: '0.8rem'}}
                      label="Model Owner"
                      user={user}
                      key={user.id}
                      actions={
                        !externalModel &&
                        user.id !== currentUserId &&
                        user.id !== form.controls.vestLead.value?.[0]?.id ? (
                          <>
                            <IconButton
                              style={{float: 'right'}}
                              aria-label="Remove"
                              size="small"
                              onClick={() => handleDelete(user, 'owners')}
                              {...tid('user-card-remove', user.id)}>
                              <CloseIcon />
                            </IconButton>
                            {webModel && (
                              <span style={{display: data.model.uuid ? 'inline' : 'none'}}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  //className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-outlinedSizeSmall MuiButton-sizeSmall"
                                  onClick={(event) => openModal(event, user)}
                                  style={{display: 'flow-root'}}>
                                  Add Expertise
                                </Button>

                                {Modal}
                              </span>
                            )}
                          </>
                        ) : (
                          displayExpertiseLevel(user)
                        )
                      }
                    />
                  ))}
                </Grid>
              </Grid>
              <Grid container item direction="column" sm={12} md={6} style={{paddingRight: '3rem'}}>
                <Grid item>
                  <ActionHeader label="Grant">
                    Permissions to view and
                    <br />
                    download this Model
                  </ActionHeader>
                </Grid>
                <GridItem>
                  <AutocompleteUserControl
                    name="viewers"
                    label="Model Viewers"
                    disabled={theoremModel && !canEditTHModelAccess(model)}
                    userFindOptions={userFindOptions}
                    handleSecurityAPI={handleSecurityAPIError}
                    restrictAccessOfExternalUser={restrictAccessOfExternalUser}
                    model={model}
                    theoremModel={theoremModel}
                    AutocompleteProps={{
                      disableClearable: true,
                      multiple: true,
                      renderTags: () => null,
                      getOptionDisabled: (option) =>
                        form.controls.modelSteward.value?.some((u: UserDTO) => u.id === option.id) ||
                        form.controls.owners.value?.some((u: UserDTO) => u.id === option.id) ||
                        form.controls.viewers.value?.some((u: UserDTO) => u.id === option.id),
                    }}
                  />
                </GridItem>

                <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
                  {form.controls.viewers.value.map((user: UserDTO) => (
                    <UserCard
                      style={{marginBottom: '0.8rem'}}
                      label="Model Viewer"
                      user={user}
                      key={user.id}
                      actions={
                        <>
                          <IconButton
                            style={{float: 'right'}}
                            aria-label="Remove"
                            size="small"
                            onClick={() => handleDelete(user, 'viewers')}
                            {...tid('user-card-remove', user.id)}>
                            <CloseIcon />
                          </IconButton>
                          {webModel && (
                            <span style={{display: data.model.uuid ? 'inline' : 'none'}}>
                              <Button
                                variant="outlined"
                                color="primary"
                                //className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-outlinedSizeSmall MuiButton-sizeSmall"
                                onClick={(event) => openModal(event, user)}
                                style={{display: 'flow-root'}}>
                                Add Expertise
                              </Button>

                              {Modal}
                            </span>
                          )}
                        </>
                      }
                    />
                  ))}
                </Grid>
              </Grid>
              {webModel && (
                <Grid container item direction="column" sm={12} md={6} style={{paddingRight: '3rem'}}>
                  <Grid item>
                    <ActionHeader label="Grant">
                      Calibration Management
                      <br />
                      Permissions <small style={{fontSize: 12}}>( for WebModel 2.0 )</small>
                    </ActionHeader>
                  </Grid>
                  <GridItem>
                    <AutocompleteUserControl
                      name="calibrationModelers"
                      label="Calibration Modelers"
                      userFindOptions={userFindOptions}
                      restrictAccessOfExternalUser={restrictAccessOfExternalUser}
                      model={model}
                      AutocompleteProps={{
                        disableClearable: true,
                        multiple: true,
                        renderTags: () => null,
                        getOptionDisabled: (option) =>
                          form.controls.modelSteward.value?.some((u: UserDTO) => u.id === option.id) ||
                          form.controls.owners.value?.some((u: UserDTO) => u.id === option.id) ||
                          form.controls.viewers.value?.some((u: UserDTO) => u.id === option.id) ||
                          form.controls.calibrationModelers.value?.some((u: UserDTO) => u.id === option.id),
                      }}
                    />
                  </GridItem>

                  <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
                    {form.controls.calibrationModelers.value.map((user: UserDTO) => (
                      <UserCard
                        style={{marginBottom: '0.8rem'}}
                        label="Calibration Modeler"
                        user={user}
                        key={user.id}
                        actions={
                          !externalModel ? (
                            <>
                              <IconButton
                                style={{float: 'right'}}
                                aria-label="Remove"
                                size="small"
                                onClick={() => handleDelete(user, 'calibrationModelers')}
                                {...tid('user-card-remove', user.id)}>
                                <CloseIcon />
                              </IconButton>
                              {webModel && (
                                <span style={{display: data.model.uuid ? 'inline' : 'none'}}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    //className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-outlinedSizeSmall MuiButton-sizeSmall"
                                    onClick={(event) => openModal(event, user)}
                                    style={{display: 'flow-root'}}>
                                    Add Expertise
                                  </Button>

                                  {Modal}
                                </span>
                              )}
                            </>
                          ) : (
                            <></>
                          )
                        }
                      />
                    ))}
                  </Grid>
                </Grid>
              )}

              {webModel && (
                <Grid container item direction="column" sm={12} md={6} style={{paddingRight: '3rem'}}>
                  <Grid item>
                    <ActionHeader label="Grant">
                      Scenario Management
                      <br />
                      Permissions <small style={{fontSize: 12}}>( for WebModel 2.0 )</small>
                    </ActionHeader>
                  </Grid>
                  <GridItem>
                    <AutocompleteUserControl
                      name="scenarioModelers"
                      label="Scenario Modelers"
                      userFindOptions={userFindOptions}
                      restrictAccessOfExternalUser={restrictAccessOfExternalUser}
                      model={model}
                      AutocompleteProps={{
                        disableClearable: true,
                        multiple: true,
                        renderTags: () => null,
                        getOptionDisabled: (option) =>
                          form.controls.modelSteward.value?.some((u: UserDTO) => u.id === option.id) ||
                          form.controls.owners.value?.some((u: UserDTO) => u.id === option.id) ||
                          form.controls.viewers.value?.some((u: UserDTO) => u.id === option.id) ||
                          form.controls.calibrationModelers.value?.some((u: UserDTO) => u.id === option.id) ||
                          form.controls.scenarioModelers.value?.some((u: UserDTO) => u.id === option.id),
                      }}
                    />
                  </GridItem>

                  <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
                    {form.controls.scenarioModelers.value.map((user: UserDTO) => (
                      <UserCard
                        style={{marginBottom: '0.8rem'}}
                        label="Scenario Modeler"
                        user={user}
                        key={user.id}
                        actions={
                          !externalModel ? (
                            <>
                              <IconButton
                                style={{float: 'right'}}
                                aria-label="Remove"
                                size="small"
                                onClick={() => handleDelete(user, 'scenarioModelers')}
                                {...tid('user-card-remove', user.id)}>
                                <CloseIcon />
                              </IconButton>
                              {webModel && (
                                <span style={{display: data.model.uuid ? 'inline' : 'none'}}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    //className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-outlinedSizeSmall MuiButton-sizeSmall"
                                    onClick={(event) => openModal(event, user)}
                                    style={{display: 'flow-root'}}>
                                    Add Expertise
                                  </Button>

                                  {Modal}
                                </span>
                              )}
                            </>
                          ) : (
                            <></>
                          )
                        }
                      />
                    ))}
                  </Grid>
                </Grid>
              )}

              {webModel && (
                <Grid container item direction="column" sm={12} md={6} style={{paddingRight: '3rem'}}>
                  <Grid item>
                    <ActionHeader label="Grant">
                      Display User
                      <br />
                      Permissions <small style={{fontSize: 12}}>( for WebModel 2.0 )</small>
                    </ActionHeader>
                  </Grid>
                  <GridItem>
                    <AutocompleteUserControl
                      name="displayViewers"
                      label="Model Display Viewers"
                      userFindOptions={userFindOptions}
                      restrictAccessOfExternalUser={restrictAccessOfExternalUser}
                      model={model}
                      AutocompleteProps={{
                        disableClearable: true,
                        multiple: true,
                        renderTags: () => null,
                        getOptionDisabled: (option) =>
                          form.controls.modelSteward.value?.some((u: UserDTO) => u.id === option.id) ||
                          form.controls.owners.value?.some((u: UserDTO) => u.id === option.id) ||
                          form.controls.displayViewers.value?.some((u: UserDTO) => u.id === option.id),
                      }}
                    />
                  </GridItem>

                  <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
                    {form.controls.displayViewers.value.map((user: UserDTO) => (
                      <UserCard
                        style={{marginBottom: '0.8rem'}}
                        label="Model Display Viewer"
                        user={user}
                        key={user.id}
                        actions={
                          !externalModel ? (
                            <>
                              <IconButton
                                style={{float: 'right'}}
                                aria-label="Remove"
                                size="small"
                                onClick={() => handleDelete(user, 'displayViewers')}
                                {...tid('user-card-remove', user.id)}>
                                <CloseIcon />
                              </IconButton>
                              {webModel && (
                                <span style={{display: data.model.uuid ? 'inline' : 'none'}}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    //className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-outlinedSizeSmall MuiButton-sizeSmall"
                                    onClick={(event) => openModal(event, user)}
                                    style={{display: 'flow-root'}}>
                                    Add Expertise
                                  </Button>

                                  {Modal}
                                </span>
                              )}
                            </>
                          ) : (
                            <></>
                          )
                        }
                      />
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
            {buttons(renderParams)}
          </>
        );
      }}
    />
  );
};

export default ModelEditPermissionsForm;
