import React, {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import {styles} from './styles';
import {tid} from '../../../../../../testUtils';
import FileTree from '../../../../../core/FileTree/FileTree';
import {FolderAdd16, PageFirst16, Add16} from '@carbon/icons-react';
import {
  ModelFileTags,
  ModelFileUploaded,
  ModelTreeNode,
  TreeNode,
  useModelFileUploader,
  useModelFileTreeTHLoader,
} from '../../../../../../store/modelFile';
import DialogConfirmation from '../../../../../core/dialog/DialogConfirmation';
import {calculateFilePath, ModelEditFilesMode, onDocumentDragOver} from './utils';
import {calculateFileName, nodeHasTag} from '../../../../../../store/modelFile/utils';
import {IconButton, Tab, Tabs} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {ModelDTO} from 'hemwb-api';
import {Form, useForm} from '../../../../../core/Form';
import {FormGroup} from 'react-reactive-form';
import {isModelExternal, isModelWeb, isModelTheorem, isModelPneuInsights} from '../../../../../../store/model';
import Checkbox from '@material-ui/core/Checkbox';
import FileTagSelectionBox from './FileTagSelectionBox';
import style from '../../../list/ModelList.module.scss';
import FileTreeTH from '../../../../../core/FileTree/FileTreeTH';

type PropertySelection = {[uuid: string]: boolean};
type FileManagerProps = {
  model: ModelDTO;
  files?: ModelTreeNode[];
  mode: ModelEditFilesMode;
  onEdit: () => void;
  onChange?: (updatedModel: ModelDTO, updatedFiles: ModelTreeNode[]) => void;
  onSubmit: (updatedModel: ModelDTO, updatedFiles: ModelTreeNode[]) => void;
  buttons: () => string | ReactNode;
  createFlow?: boolean;
  uploadFlow?: boolean;
  cloneFlow?: boolean;
  unSelectAll?: boolean;
  hideUploadFileButton?: boolean;
  hideDeleteFileButton?: boolean;
  filesSelection?: PropertySelection;
  filteredFiles?: boolean;
};

const FileManager: React.FC<FileManagerProps> = ({
  buttons,
  createFlow,
  uploadFlow,
  cloneFlow,
  onSubmit,
  onChange,
  onEdit,
  model,
  files,
  mode,
  hideUploadFileButton,
  hideDeleteFileButton,
  filteredFiles,
}) => {
  const {add, markDelete, unmarkDelete, remove, markForFileTag, uploader} = useModelFileUploader(model, files);
  const filteredFileUuids = new Set(files?.map((file) => file.uuid));
  const nodes =
    files && filteredFiles
      ? uploader.nodes.filter((node) => filteredFileUuids.has(node.uuid) || !node.uploaded)
      : uploader.nodes;
  const [initialized, setInitialized] = useState(false);
  const externalModel = isModelExternal(model);
  const webModel = isModelWeb(model);
  const theoremModel = isModelTheorem(model);
  const pneuInsightsModel = isModelPneuInsights(model);
  const [selected, setSelected] = useState<PropertySelection>({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [unSelectAll, setUnSelectAll] = useState(false);
  const [tab, setTab] = useState('theorem');
  const {data: THfiles, load: loadFiles} = useModelFileTreeTHLoader(model.uuid);

  useEffect(() => {
    if (theoremModel && tab === 'theorem') {
      loadFiles();
    }
  }, [tab, loadFiles, theoremModel]);

  useEffect(() => {
    const newSelected = {...selected};
    for (const node of nodes) {
      if (getNodeIdentifier(node) in newSelected) continue;
      newSelected[getNodeIdentifier(node)] = false;
    }
    setSelected(newSelected);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setSpecificFiles = useCallback(
    (uuids: string[], checked: boolean) => {
      const newSelected = {...selected};
      for (const uuid of uuids) {
        newSelected[uuid] = checked;
      }
      setSelected(newSelected);
      const selectedFilesCount = newSelected ? Object.values(newSelected).filter((value) => value).length : 0;
      setSelectedCount(selectedFilesCount);
    },
    [selected],
  );

  const unSelectAllFiles = () => {
    for (const uuid in selected) {
      selected[uuid] = false;
    }
    setSelectedCount(0);
    setUnSelectAll(true);
  };

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      onChange?.(model, nodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, initialized]);

  useEffect(() => {
    document.addEventListener('dragover', onDocumentDragOver, false);
    document.addEventListener('drop', onDocumentDragOver, false);
    return () => {
      document.removeEventListener('dragover', onDocumentDragOver);
      document.removeEventListener('drop', onDocumentDragOver, false);
    };
  }, []);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const folderInputRef = useRef<HTMLInputElement>(null);

  const isDirUploadAvailable = useMemo(() => {
    const input = document.createElement('input');
    // @ts-ignore
    return typeof input.webkitdirectory === 'boolean';
  }, []);

  const dispatchAddFile = useCallback(
    (file: File, node: TreeNode | string) => {
      let path;
      if (typeof node === 'string') {
        path = node;
      } else {
        const pathName = `${node.path}/`;
        path = `${node.path === '' ? '' : pathName}${calculateFileName(file.name)}`;
      }

      add(file, path);

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      if (folderInputRef.current) {
        folderInputRef.current.value = '';
      }
    },
    [add],
  );

  const handleAddFiles = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      onEdit();
      if (event.currentTarget.files) {
        Array.from(event.currentTarget.files).forEach((file: File) => {
          // @ts-ignore
          const path = calculateFilePath(calculateFileName(file.name), file.webkitRelativePath);
          dispatchAddFile(file, path);
        });
      }
    },
    [dispatchAddFile, onEdit],
  );

  const [confirmNodeDeletion, setConfirmNodeDeletion] = useState<ModelFileUploaded | null>(null);
  const handleDeleteFile = useCallback(
    (node: ModelFileUploaded) => {
      if (node.mainFile) {
        setConfirmNodeDeletion(node);
      } else {
        markDelete(node);
      }
    },
    [markDelete],
  );
  const handleConfirmDeleteFile = useCallback(() => {
    if (confirmNodeDeletion) {
      markDelete(confirmNodeDeletion);
    }
    setConfirmNodeDeletion(null);
  }, [markDelete, confirmNodeDeletion]);

  const handleDeclineDeleteFile = useCallback(() => {
    setConfirmNodeDeletion(null);
  }, []);

  const handleSaveFileTag = useCallback(
    (fileTag: string) => {
      for (const node of nodes) {
        if (selected[getNodeIdentifier(node)]) {
          markForFileTag(node, fileTag);
          selected[getNodeIdentifier(node)] = false;
        }
        setUnSelectAll(true);
        setSelectedCount(0);
      }
    },
    [markForFileTag, selectedCount, setUnSelectAll, selected], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getNodeIdentifier = (node: ModelTreeNode) => (node.uuid ? node.uuid : node.path);

  const handleFileTag = useCallback(
    (node: ModelFileUploaded, fileTag: string) => {
      markForFileTag(node, fileTag);
    },
    [markForFileTag],
  );

  const handleDrop = useCallback(
    (event: React.DragEvent, node: TreeNode) => {
      if (!node || !node.folder || pneuInsightsModel) {
        return;
      }

      function traverseFileTree(item: any, p?: string) {
        const path = p || '';
        if (item.isFile) {
          // Get file
          item.file(function (file: File) {
            const newPath = `${node.path}${node.path ? '/' : ''}${path}${file.name}`;
            dispatchAddFile(file, newPath);
          });
        } else if (item.isDirectory) {
          // Get folder contents
          const dirReader = item.createReader();
          dirReader.readEntries(function (entries: any[]) {
            for (const entry of entries) {
              traverseFileTree(entry, path + item.name + '/');
            }
          });
        }
      }

      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (const item of event.dataTransfer.items) {
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            const entry = item.webkitGetAsEntry();
            if (entry) {
              traverseFileTree(entry);
            } else {
              const file = item.getAsFile();
              if (file) {
                dispatchAddFile(file, node);
              }
            }
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s) - Fallback for older browsers like IE11
        for (const file of event.dataTransfer.files) {
          dispatchAddFile(file, node);
        }
      }
    },
    [dispatchAddFile, pneuInsightsModel],
  );

  const renderButtons = useCallback(
    (node: TreeNode) => {
      if (node.folder || (nodeHasTag(node, ModelFileTags.BITBUCKET_FILE) && !node.uploaded)) {
        return null;
      }

      if (nodeHasTag(node, ModelFileTags.PARSING)) {
        return [<span key={`progress-${node.uuid}`}>Parsing...</span>];
      }

      return [
        node.uploaded &&
          !nodeHasTag(node, ModelFileTags.DELETE) &&
          !nodeHasTag(node, ModelFileTags.PUBLISHED_MAIN_FILE) &&
          !nodeHasTag(node, ModelFileTags.PUBLISHED_MODEL_FILE) &&
          !pneuInsightsModel && (
            <div className={styles.buttonContainer}>
              <div className={styles.checkBoxContainer}>
                <Checkbox
                  key={`selected-${node.uuid}`}
                  color="primary"
                  checked={selected[node.uuid] || false}
                  onChange={(event, checked) => {
                    const isChecked = checked;
                    setUnSelectAll(false);
                    setSelected({...selected, [node.uuid]: isChecked});
                    if (isChecked) {
                      setSelectedCount(selectedCount + 1);
                    } else {
                      setSelectedCount(Math.max(selectedCount - 1, 0));
                    }
                  }}
                />
              </div>
              <div className={styles.buttonSpacing}></div>
              {!hideDeleteFileButton && (
                <IconButton
                  key={`delete-${node.uuid}`}
                  aria-label="Delete"
                  size="small"
                  onClick={() => {
                    handleDeleteFile(node as ModelFileUploaded);
                    setSpecificFiles([getNodeIdentifier(node as ModelTreeNode)], false);
                  }}
                  {...tid('btn', 'delete', node.name)}>
                  {!pneuInsightsModel && <CloseIcon />}
                </IconButton>
              )}
            </div>
          ),
        node.uploaded && nodeHasTag(node, ModelFileTags.DELETE) && (
          <IconButton
            key={`undelete-${node.uuid}`}
            aria-label="Undelete"
            size="small"
            onClick={() => unmarkDelete(node as ModelFileUploaded)}
            {...tid('btn', 'undelete', node.name)}>
            <PageFirst16 />
          </IconButton>
        ),
        !node.uploaded && (
          <>
            <div className={styles.buttonContainer}>
              <div className={styles.uploadCheckBoxContainer}>
                <Checkbox
                  key={`selected-${getNodeIdentifier(node as ModelTreeNode)}`}
                  color="primary"
                  checked={selected[getNodeIdentifier(node as ModelTreeNode)] || false}
                  onChange={(event, checked) => {
                    const isChecked = checked;
                    setUnSelectAll(false);
                    setSelected({...selected, [getNodeIdentifier(node as ModelTreeNode)]: isChecked});
                    if (isChecked) {
                      setSelectedCount(selectedCount + 1);
                    } else {
                      setSelectedCount(Math.max(selectedCount - 1, 0));
                    }
                  }}
                />
              </div>
              {!hideDeleteFileButton && (
                <IconButton
                  key={`remove-${node.uuid}`}
                  size="small"
                  onClick={() => {
                    remove(node as ModelTreeNode);
                    setSpecificFiles([getNodeIdentifier(node as ModelTreeNode)], false);
                  }}
                  {...tid('btn', 'remove', node.name)}>
                  {!pneuInsightsModel && <CloseIcon />}
                </IconButton>
              )}
            </div>
          </>
        ),
      ].filter((button) => button);
    },
    [
      remove,
      unmarkDelete,
      handleDeleteFile,
      pneuInsightsModel,
      selectedCount,
      selected,
      setSpecificFiles,
      hideDeleteFileButton,
    ],
  );

  const form = useForm(new FormGroup({}));

  return (
    <>
      {theoremModel && (
        <div style={{justifyContent: 'center', display: 'flex', backgroundColor: '#f4f4f4'}}>
          <Tabs
            value={tab}
            onChange={(event: React.ChangeEvent<{}>, newTab: string) => setTab(newTab)}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            centered
            classes={{indicator: style.indicator}}>
            <Tab value="theorem" label="THEOREM files" />
            <Tab value="supporting" label="Supporting Documents" />
          </Tabs>
        </div>
      )}
      <div style={{marginTop: '2rem'}}>
        {tab === 'theorem' && theoremModel && THfiles && (
          <>
            <span style={{fontSize: '15px', fontWeight: 'bold'}}>THEOREM Files</span>
            <br />
            <br />
            <FileTreeTH externalModel={externalModel} files={THfiles} editFlow={true} />
          </>
        )}
      </div>

      {(!theoremModel || (theoremModel && tab === 'supporting')) && (
        <>
          <DialogConfirmation
            open={!!confirmNodeDeletion}
            onClickYes={handleConfirmDeleteFile}
            onClickNo={handleDeclineDeleteFile}
            text={`${confirmNodeDeletion?.name || ''} is a main model file. Are you sure you want to delete it?`}
          />

          <div className={clsx(styles.panel, styles.panelMlmp)}>
            {mode !== ModelEditFilesMode.BITBUCKET && !hideUploadFileButton && (
              <div className={styles.title}>Upload model files from local</div>
            )}
            {mode === ModelEditFilesMode.BITBUCKET && (
              <div className={styles.title}>Upload additional files from local</div>
            )}

            {!hideUploadFileButton && (
              <label
                id="upload-button"
                className={clsx(
                  'MuiButtonBase-root MuiButton-root MuiButton-contained submitButton MuiButton-containedPrimary MuiButton-sizeLarge',
                  // eslint-disable-next-line
                  {['Mui-disabled']: pneuInsightsModel},
                )}>
                Select files
                <Add16 />
                <input
                  type="file"
                  multiple
                  onChange={handleAddFiles}
                  ref={fileInputRef}
                  {...tid('input-file')}
                  // accept={allowedExtensions.map((ext) => `.${ext}`).join(',')}
                />
              </label>
            )}
            {!hideUploadFileButton && isDirUploadAvailable && (
              <label
                id="upload-button-folder"
                className={clsx(
                  'MuiButtonBase-root  MuiButton-root MuiButton-contained submitButton MuiButton-containedPrimary MuiButton-sizeLarge',
                  // eslint-disable-next-line
                  {['Mui-disabled']: pneuInsightsModel},
                )}>
                Select folder
                <FolderAdd16 />
                <input
                  type="file"
                  webkitrelativepath="webkitrelativepath"
                  webkitdirectory="webkitdirectory"
                  onChange={handleAddFiles}
                  ref={folderInputRef}
                />
              </label>
            )}

            <div>
              {!hideUploadFileButton && (
                <>
                  <div className={styles.dropInfo}>
                    You can either select file or folder,
                    <br />
                    or drag and drop.
                  </div>

                  <div
                    className={clsx(styles.dropZone, {[styles.dropDisabled]: pneuInsightsModel})}
                    onDrop={
                      !pneuInsightsModel
                        ? (event: React.DragEvent) =>
                            handleDrop(event, {uuid: '', path: '', name: '', folder: true, uploaded: true})
                        : undefined
                    }>
                    Drag and drop files
                    <br /> here or upload
                  </div>
                </>
              )}
            </div>

            {selectedCount > 1 && (
              <FileTagSelectionBox
                selectedFileCount={selectedCount}
                onClick={handleSaveFileTag}
                onCancel={unSelectAllFiles}
                setUnSelectAll={setUnSelectAll}
                unSelectAll={unSelectAll}
              />
            )}
            {theoremModel && nodes && nodes.length > 0 && (
              <span style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '2rem'}}>Supporting Documents</span>
            )}
            {nodes && nodes.length > 0 && (
              <FileTree
                renderButtons={renderButtons}
                files={nodes}
                handleFileTag={handleFileTag}
                createFlow={createFlow}
                onDrop={handleDrop}
                webModel={webModel}
                setSpecificFiles={setSpecificFiles}
                unSelectAll={unSelectAll}
                uploadFlow={uploadFlow}
                cloneFlow={cloneFlow}
                theoremModel={theoremModel}
              />
            )}
            <br />
            <br />
            <Form
              className=""
              FieldGroupProps={{strict: false}}
              group={form}
              onSubmit={() => onSubmit(model, nodes)}
              render={() => {
                return <>{buttons()}</>;
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default FileManager;
