import React from 'react';

import ModelEditFileTypes, {ModelEditFileTypesProps} from './ModelEditFileTypes';
import {PropertySelection} from './types';

type FileTagProps = {
  filesSelection: PropertySelection;
  toggleFile: (uuid: string) => void;
};

type StepFileTagProps = Omit<ModelEditFileTypesProps, 'buttons'> & FileTagProps;

const renderButtons = () => {
  return null;
};

const StepFileTypes: React.FC<StepFileTagProps> = (props) => {
  const {files, filesSelection} = props;
  const filteredFiles = files?.filter((file) => filesSelection[file.uuid]);

  return (
    <div>
      <ModelEditFileTypes createFlow={true} buttons={renderButtons} {...props} files={filteredFiles} />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default StepFileTypes;
