import {VisualizationResults} from './types';

type Valid = [VisualizationResults];
type Invalid = [undefined, string];

export const visualizationResultsValidator = (input: string, isJsonType: boolean): Valid | Invalid => {
  try {
    if (!isJsonType) {
      return [
        {
          options: {},
          elements: [],
          isJsonType: true,
        },
      ];
    }

    const data = JSON.parse(input);

    if (Array.isArray(data)) {
      Object.keys(data).map((key) => {
        // @ts-ignore
        if (data[key].element === 'Section' && !data[key].text) {
          // @ts-ignore
          data[key].text = '';
        }
        // @ts-ignore
        return data[key];
      });

      return [
        {
          options: {},
          elements: data,
        },
      ];
    }

    if (Array.isArray(data?.elements)) {
      Object.keys(data.elements).map((key) => {
        if (data.elements[key].element === 'Section' && !data.elements[key].text) {
          data.elements[key].text = '';
        }
        return data[key];
      });
      return [
        {
          options: data.options || {},
          elements: data.elements,
        },
      ];
    }

    return [undefined, 'Invalid data structure'];
  } catch (e) {
    return [undefined, e.message];
  }
};
