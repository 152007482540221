import React, {useCallback, useState} from 'react';
import {SubModelActionProps} from '../../../actionMenuTypes';
import {useDispatch} from 'react-redux';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {tid} from '../../../../../testUtils';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {TrashCan16} from '@carbon/icons-react';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {executionSetupDelete, typeToSection} from '../../../../../store/executionSetup';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import styles from '../../../../core/Form/CheckboxControl.module.scss';
import {useMounted} from '../../../../../hooks/useMounted';
import {trackSubModelDeleted} from '../../../../../tracking/tracking';
import {getUrlRoute, Routes, useRoute} from '../../../../router/routes';
import {useHistory} from 'react-router';
import {useLastValidValue} from '../../../../../hooks/useLastValidValue';

const SubModelDelete: React.FC<SubModelActionProps> = ({model, subModel: _subModel, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const history = useHistory();
  const subModel = useLastValidValue(_subModel);

  const {subModelId} = useRoute<Routes.MODEL_INPUT_EDITOR>();

  const [submitting, setSubmitting] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);

    try {
      if (Number(subModelId) === subModel.id) {
        history.push(getUrlRoute(Routes.SUBMODEL_LIST, {modelUuid: model.uuid, section: typeToSection(subModel.type)}));
      }
      await executionSetupDelete(dispatch, subModel.id);
      trackSubModelDeleted(model, subModel);
      dispatch(messageAdd('Sub-model has been deleted', MessageTypes.SUCCESS));
      mounted.current && setSubmitting(false);
      onSuccess?.();
    } catch (e) {
      setSubmitting(false);
    }
  }, [dispatch, history, model, subModel, mounted, onSuccess, subModelId]);

  return (
    <>
      <h2>Delete sub-model</h2>
      <FormControlLabel
        classes={{root: styles.checkboxTopLabelRoot}}
        control={
          <Checkbox
            checked={confirmed}
            classes={{root: styles.checkboxTopCheckboxRoot}}
            color="secondary"
            onChange={() => setConfirmed(!confirmed)}
            name="confirm"
            {...tid('checkbox-option')}
          />
        }
        label="I confirm that this sub-model will be permanently deleted."
      />

      <div className="buttonsContainer">
        <br />
        <br />
        <CancelButton onClick={onCancel} className="redButton" {...tid(SUBMODEL_ACTION_MENU_IDS.DELETE, 'cancel')} />
        <SubmitButton
          onClick={handleSubmit}
          active={submitting}
          disabled={!confirmed}
          className="redButton"
          endIcon={<TrashCan16 />}
          {...tid(SUBMODEL_ACTION_MENU_IDS.DELETE, 'submit')}>
          Delete sub-model
        </SubmitButton>
      </div>
    </>
  );
};

export default SubModelDelete;
