import React from 'react';
import {useDispatch} from 'react-redux';
import {Typography} from '@material-ui/core';
import {messageAdd, MessageTypes} from '../../../store/message';
import {requestSecurityAPI} from '../../../store/auth/api';
import {UserType} from 'hemwb-api';
import {hardCodedOptions} from '../../../components/pages/model/common/ModelEdit/ModelEditMetadata/constants';

type AutocompleteItemProps = {
  primary: string;
  secondary: string;
  ownershipType?: string;
  isid?: string;
  modelUuid?: string;
  theoremModel?: boolean;
  fileUuid?: string;
  name?: string;
  userType?: string;
  handleSecurityAPI?: () => void;
  restrictAccessOfExternalUser?: (name: string) => void;
  informationClassification?: string;
};

export const AutocompleteItem: React.FC<AutocompleteItemProps> = ({
  primary,
  secondary,
  ownershipType,
  isid,
  modelUuid,
  theoremModel,
  fileUuid,
  userType,
  handleSecurityAPI,
  restrictAccessOfExternalUser,
  informationClassification,
  name,
  ...rest
}) => {
  const dispatch = useDispatch();
  const classificationOptions = hardCodedOptions['information_classification'];
  const errorText = theoremModel ? 'Theorem model' : 'sensitive model';

  const handleOnClick = () => {
    if (
      restrictAccessOfExternalUser &&
      name &&
      userType === UserType.EXTERNALUSER &&
      !['modelSteward', 'vestLead'].includes(name)
    ) {
      if (theoremModel || (classificationOptions && informationClassification === classificationOptions[3])) {
        setTimeout(() => restrictAccessOfExternalUser(name), 100);
        dispatch(messageAdd(`External user access cannot be added as this is a ${errorText}.`, MessageTypes.ERROR));
      }
    } else if (isid && modelUuid && theoremModel && handleSecurityAPI) {
      requestSecurityAPI({securityAPIDTO: {isid: isid, modelUUID: modelUuid, fileUuid: fileUuid}})
        .then(() => {})
        .catch((error) => {
          if (error.status === 500) {
            handleSecurityAPI();
            dispatch(
              messageAdd(
                `This User must request access in THEOREM. Please have the User <a href="https://collaboration.merck.com/sites/THEOREM/Lists/FAQ%20%20THEOREM/DispForm.aspx?ID=53&e=mvH1Me">follow these steps</a> to request access in THEOREM.`,
                MessageTypes.ERROR,
              ),
            );
          }
        });
    }
  };

  return (
    <div {...rest} onClick={handleOnClick}>
      {ownershipType && <Typography variant="body1">{ownershipType}</Typography>}
      <Typography variant="body1">{primary}</Typography>
      <Typography variant="body2" color="textSecondary">
        {secondary}
      </Typography>
    </div>
  );
};
