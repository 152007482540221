import React, {useState, useCallback, useRef, useEffect} from 'react';
import {FileInputAttributes, InputType} from '../../InputDefinition/types';
import {InputGenerator} from '../types';
import {FormControl, Validators} from 'react-reactive-form';
import InputHeader from './InputHeader';
import {Tooltip, Typography, Chip, CircularProgress, Button} from '@material-ui/core';
import {Add, InfoOutlined} from '@material-ui/icons';
import clsx from 'clsx';
import styles from '../../../../core/Buttons/FileInputButton.module.scss';
import {CalibrationScenarioInputs} from '../../../../core/ModelInput/ScenarioParameters/types';
import StaticInputValuePrimitive from '../primitives/StaticInputValuePrimitive';
import {useDispatch} from 'react-redux';
import {
  modelHelpFileDownload,
  calculateFileName,
  requestUploadParameterFile,
  modelFileDelete,
} from '../../../../../store/modelFile';
import {downloadFromResponse} from '../../../../../utils/download';
import {ApiResponse, ModelItemType} from 'hemwb-api';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {calculateFilePath} from '../../../../pages/model/common/ModelEdit/ModelEditFiles/utils';
import {Download16} from '@carbon/icons-react';

type FileInputProps = {
  definition: FileInputAttributes;
  modelUuid: string;
  control: FormControl;
  values: CalibrationScenarioInputs;
};

const FileInput: React.FC<FileInputProps> = ({definition, modelUuid, control, values}) => {
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>();
  const [extension, setExtension] = useState<string | null | undefined>();
  const [display, setDisplay] = useState<boolean>(true);
  const [itemUuid, setItemUuid] = useState<string>();
  const dispatch = useDispatch();
  const cancelled = useRef(false);
  const scenarioName = definition.name;
  //const parameterName = `${definition.title}${extension}`;
  const allowedFileExtensions = [
    '.xls',
    '.xlsx',
    '.csv',
    '.tsv',
    '.mtp',
    '.json',
    '.txt',
    '.m',
    '.wl',
    '.wls',
    '.rdata',
    '.rda',
    '.sas7bdat',
    '.stx',
    '.xpt',
    '.dta',
    '.por',
    '.sav',
    '.zsav',
  ];
  const browserFileExtensions = allowedFileExtensions
    .concat(allowedFileExtensions.map((extension) => extension.toUpperCase()))
    .join(',');
  const downloadIfNotCancelled = useCallback(
    (response: ApiResponse<Blob>) => (cancelled.current ? Promise.reject() : downloadFromResponse(response.raw)),
    [],
  );

  const handleHelpFileDownload = useCallback(() => {
    cancelled.current = false;
    modelHelpFileDownload(dispatch, modelUuid, definition.files)
      .then(downloadIfNotCancelled)
      .catch(() => null);
  }, [dispatch, modelUuid, definition.files, downloadIfNotCancelled]);

  const handleParameterFileDownload = useCallback(() => {
    cancelled.current = false;
    if (itemUuid) {
      modelHelpFileDownload(dispatch, modelUuid, itemUuid)
        .then(downloadIfNotCancelled)
        .catch(() => null);
    }
  }, [dispatch, modelUuid, itemUuid, downloadIfNotCancelled]);

  const handleDelete = useCallback(() => {
    if (itemUuid) {
      setItemUuid(undefined);
      setFileName(undefined);
      setFile(undefined);
      setDisplay(false);
      setExtension(undefined);
      modelFileDelete(dispatch, modelUuid, itemUuid).catch(() => null);
    } else {
      setFile(undefined);
      setExtension(undefined);
    }
    control.setValue(null);
  }, [dispatch, modelUuid, itemUuid, control]);

  const getFileExtension = (name: string) => {
    if (name) {
      const parts = name.split('.');
      if (parts.length > 1) {
        return '.' + parts.pop();
      }
    }
  };

  const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files && event.currentTarget.files[0];

    if (selectedFile) {
      const fileExtension = getFileExtension(selectedFile.name);
      setFile(selectedFile);
      setExtension(fileExtension);
      if (selectedFile.size <= 1048576 && fileExtension && browserFileExtensions.includes(fileExtension)) {
        const path = calculateFilePath(calculateFileName(selectedFile.name));
        requestUploadParameterFile({
          modelUuid: modelUuid,
          type: ModelItemType.EXECUTION,
          file: selectedFile,
          path: path,
          fileTag: 'Parameter',
          parameterName: definition.title,
        })
          .then((response: React.SetStateAction<string | undefined>) => {
            setItemUuid(response);
            control.setValue({file: response, name: path});
          })
          .catch((error: {status: number}) => {
            if (error.status === 409) {
              dispatch(
                messageAdd(
                  'File already exists, either choose a different file or rename the new file.',
                  MessageTypes.ERROR,
                ),
              );
            } else {
              dispatch(messageAdd('Failed to update Files.', MessageTypes.ERROR));
            }
          });
      } else {
        control.setValue(null);
      }
    }
  };

  useEffect(() => {
    setItemUuid(values[scenarioName]?.file);
    setFileName(values[scenarioName]?.name);
    //const uploadedFileExtension = getFileExtension(values[scenarioName]?.name);
    //if (uploadedFileExtension) setFileName(`${definition.title}${uploadedFileExtension}`);
  }, [values, scenarioName, definition]);

  useEffect(() => {
    if (!file && values[scenarioName] && display) {
      control.setValue({file: values[scenarioName].file, name: values[scenarioName].name});
    }
  }, [values, scenarioName, display, control, file]);

  const isItemUuidPresent = useCallback(() => {
    if (itemUuid && file) {
      return <Chip key={itemUuid} label={file.name} onDelete={handleDelete} variant="outlined" />;
    } else {
      return <CircularProgress size={24} />;
    }
  }, [itemUuid, handleDelete, file]);

  return (
    <>
      <InputHeader definition={definition} />
      <div>
        Add a file or replace the existing file to upload the parameter value.
        {definition.required && <span style={{color: '#FF5656'}}>*</span>}
        <Tooltip
          title={
            <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
              Click here for information about the file{'\n'} content and Structure ( maximum size of {'\n'} the file
              should not be more that 1 MB){'\n'}
              <span
                style={{
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={handleHelpFileDownload}>
                More Info
              </span>
            </Typography>
          }
          placement="right"
          arrow
          interactive>
          <InfoOutlined style={{marginLeft: '10px', verticalAlign: 'middle', fontSize: '23px'}} />
        </Tooltip>
      </div>
      <br />
      <label
        id="upload-button"
        className={clsx(
          'MuiButtonBase-root MuiButton-root MuiButton-outlined submitButton MuiButton-outlinedPrimary ',
          styles.labelButton,
          // eslint-disable-next-line,
        )}>
        {itemUuid ? 'Replace File' : 'Add File'}
        <Add />
        <input type="file" name="value" onChange={handleSubmit} accept={browserFileExtensions} />
      </label>
      {itemUuid && (
        <span style={{marginLeft: '20px'}}>
          <Button variant="contained" color="primary" onClick={handleParameterFileDownload}>
            Download File
            <Download16 style={{marginLeft: '10px'}} />
          </Button>
        </span>
      )}
      <br />
      {!file && definition.required && <span style={{color: '#FF5656', fontSize: '12px'}}>File is required </span>}
      <br />
      {file &&
        extension &&
        (file.size <= 1048576 && browserFileExtensions.includes(extension) ? (
          isItemUuidPresent()
        ) : (
          <div>
            <Chip label={file.name} onDelete={handleDelete} variant="outlined" />
            <br />
            <br />
            {file.size > 1048576 && (
              <span style={{color: 'red'}}>
                File exceeds 1 MB limit. Reduce the file size and try again.
                <br />
              </span>
            )}

            {!browserFileExtensions.includes(extension) && (
              <span style={{color: 'red'}}>
                File extension is unsupported, examples of supported extensions are: (.XLS, .XLSX, .CSV, .TSV, .mtp),
                JSON (.json), Text (.txt), Mathematica (.m, .wl, .wls), R (.rdata, .rda), SAS and other database formats
                (.sas7bdat, stx, xpt, dta, .por, .sav, .zsav)
              </span>
            )}
          </div>
        ))}
      {fileName && !file && display && (
        <Chip key={itemUuid} label={fileName} onDelete={handleDelete} variant="outlined" />
      )}
    </>
  );
};

export const fileInputGenerator: InputGenerator<InputType.FILE> = {
  type: InputType.FILE,
  buildControl: (definition, initialValues) => {
    const validators = [];
    if (definition.required) {
      validators.push(Validators.required);
    }
    return new FormControl(initialValues !== null ? initialValues : null, validators);
  },
  render: FileInput,
  renderStatic: StaticInputValuePrimitive,
};
