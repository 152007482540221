import React, {useCallback, useEffect, useState} from 'react';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import {ModelDTO, ModelItemTHBaseDTO, UserAccessDTO, UserBaseDTO} from 'hemwb-api';
import {Form, useForm} from '../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {useDispatch} from 'react-redux';
import {tid} from '../../../../../testUtils';
import {Collaborate16, Password24} from '@carbon/icons-react';
import {Grid, IconButton, Tab, Tabs} from '@material-ui/core';
import GridItem from '../../../../core/Form/GridItem';
import {UserCard} from '../../../../core/UserCard/UserCard';
import CloseIcon from '@material-ui/icons/Close';
import {MODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {fullNameWestern} from '../../../../../store/user/utils';
import {useMounted} from '../../../../../hooks/useMounted';
import {grantFileOwnership} from '../../../../../store/fileOwnerAccess/api';
import AutocompleteUserControl from '../../../../core/Form/AutocompleteUserControl';
import {getUrlRoute, Routes} from '../../../../router/routes';
import ModelFileManageAccessTemplate from './ModelFileManageAccessTemplate';
import styles from '../../../../layout/common/NavBar.module.scss';

type ModelFileManageAccessDialogProps = {
  model: ModelDTO;
  open: boolean;
  fileUuid: string;
  fileName: string;
  file?: ModelItemTHBaseDTO;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const userFindOptions = {limit: 10, includeNpa: false, includeUnenrolled: true};

const ModelFileManageOwnerAccessDialog: React.FC<ModelFileManageAccessDialogProps> = ({
  model,
  open,
  onSuccess,
  onCancel,
  fileUuid,
  fileName,
  file,
}) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const [submitting, setSubmitting] = useState(false);
  const [tab, setTab] = useState('fileAccess');

  const form = useForm(
    new FormGroup({
      users: new FormControl([], Validators.required),
    }),
    {
      users: [],
    },
  );

  const handleReload = useCallback(() => {
    window.location.href = `${window.location.origin}/#${getUrlRoute(Routes.MODEL_DETAIL, {
      uuid: model.uuid,
    })}?tab=folder`;
    window.location.reload();
  }, [model]);

  const handleDeleteUser = useCallback(
    (user: UserAccessDTO) => {
      form.controls.users.setValue(form.controls.users.value.filter((u: UserAccessDTO) => u.id !== user.id));
      form.controls.users.markAsTouched();
    },
    [form],
  );

  useEffect(() => {
    if (form) {
      const onlyLastUser = () => {
        if (form.controls.users.value.length > 1) {
          form.controls.users.setValue([form.controls.users.value[form.controls.users.value.length - 1]]);
        }
      };
      form.controls.users.valueChanges.subscribe(onlyLastUser);
    }
  }, [form, model, fileUuid]);

  const handleSecurityAPIError = useCallback(() => {
    form.controls.users.setValue([]);
  }, [form]);

  const handleSubmit = useCallback(() => {
    if (!form.valid) {
      return;
    }
    setSubmitting(true);

    grantFileOwnership(fileUuid, form.controls.users.value[0].id).then(() => {
      dispatch(
        messageAdd(
          'File Ownership has been granted to ' +
            form.controls.users.value[0].firstName +
            ', ' +
            form.controls.users.value[0].lastName,
          MessageTypes.SUCCESS,
        ),
      );
      form.controls.users.setValue([]);
      mounted.current && setSubmitting(false);
      handleReload();
    });
    onSuccess?.();
  }, [form, dispatch, mounted, onSuccess, fileUuid, handleReload]);

  return (
    <DialogGeneric open={open} title=" " PaperProps={tid('dialog-request-file-access') as {}} childrenAsText={false}>
      <Tabs
        value={tab}
        onChange={(event: React.ChangeEvent<{}>, newTab: string) => setTab(newTab)}
        indicatorColor="primary"
        textColor="inherit"
        classes={{indicator: styles.indicator}}
        variant="fullWidth">
        <Tab value="fileAccess" label="File Access" />
        <Tab value="manageOwner" label="Manage Owner" />
      </Tabs>
      {tab === 'fileAccess' && (
        <ModelFileManageAccessTemplate
          model={model}
          fileUuid={fileUuid}
          fileName={fileName}
          onSuccess={onSuccess}
          onCancel={onCancel}
          displayInDialog={false}
        />
      )}
      {tab === 'manageOwner' && (
        <Form
          group={form}
          onSubmit={handleSubmit}
          {...tid('file-share-form')}
          render={({valid}) => {
            return (
              <>
                <Grid container direction="row" alignItems="flex-start" justify="space-between">
                  <Grid container item direction="column" style={{padding: '3rem'}}>
                    <GridItem>
                      <div>
                        <div style={{position: 'absolute', left: '18px', top: '125px'}}>
                          <Password24 />
                        </div>
                        <div style={{fontSize: '12px', fontWeight: 'bold'}}>Manage Owner</div>
                        <div style={{fontSize: '10px', wordBreak: 'break-word'}}>{fileName}</div>
                      </div>
                    </GridItem>

                    <GridItem>
                      <AutocompleteUserControl
                        name="users"
                        label="Select a user to change the file owner"
                        userFindOptions={userFindOptions}
                        model={model}
                        theoremModel={true}
                        handleSecurityAPI={handleSecurityAPIError}
                        AutocompleteProps={{
                          disableClearable: true,
                          multiple: true,
                          renderTags: () => null,
                          getOptionDisabled: (option) =>
                            form.controls.users.value?.some((u: UserBaseDTO) => u.id === option.id),
                        }}
                      />
                    </GridItem>
                    <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
                      {form.controls.users.value.map((user: UserBaseDTO) => (
                        <UserCard
                          style={{marginBottom: '0.8rem'}}
                          label={fullNameWestern(user)}
                          user={form.controls.users.value[0]}
                          key={form.controls.users.value[0].id}
                          actions={
                            <>
                              <IconButton
                                style={{float: 'right'}}
                                aria-label="Remove"
                                size="small"
                                onClick={() => handleDeleteUser(form.controls.users.value[0])}
                                {...tid('user-card-remove', form.controls.users.value[0].id)}>
                                <CloseIcon />
                              </IconButton>
                            </>
                          }
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>

                <div className="buttonsContainer">
                  <CancelButton
                    onClick={onCancel}
                    disabled={submitting}
                    {...tid(MODEL_ACTION_MENU_IDS.SHARE, 'cancel')}
                  />
                  <SubmitButton
                    active={submitting}
                    disabled={!valid || file?.owner.isid === form.controls.users.value[0].id}
                    endIcon={<Collaborate16 />}
                    {...tid(MODEL_ACTION_MENU_IDS.SHARE, 'submit')}>
                    Update Owner
                  </SubmitButton>
                </div>
              </>
            );
          }}
        />
      )}
      <div></div>
    </DialogGeneric>
  );
};
export default ModelFileManageOwnerAccessDialog;
