export enum MODEL_ACTION_MENU_IDS {
  // SCENARIO_MANAGEMENT = 'btn-scenario-management',
  // ADD_SCENARIO = 'btn-scenario-add',
  EDIT_FILES = 'btn-model-edit-files',
  EDIT_METADATA = 'btn-model-edit-metadata',
  EDIT_LINEAGE = 'btn-model-edit-lineage',
  // EDIT_EXECUTION = 'btn-model-edit-execution-setup',
  EDIT_PERMISSIONS = 'btn-model-edit-permissions',
  EDIT_SUFFIX = 'btn-model-edit-suffix',
  CLONE = 'btn-model-clone',
  PUBLISH = 'btn-publish-model',
  DOWNLOAD = 'btn-download-model',
  DOWNLOAD_OVERVIEW = 'btn-download-model-overview',
  DOWNLOAD_ALL_ENTRIES = 'btn-download-all-entries',
  SHARE = 'btn-share-model',
  COMPARE = 'btn-version-compare',
  HISTORY = 'btn-version-history',
  RETIRE = 'btn-retire-model',
  DELETE_DRAFT = 'btn-model-delete-draft',
}

export enum SUBMODEL_ACTION_MENU_IDS {
  LIST = 'btn-submodel-list',
  LIST_CALIBRATION = 'btn-submodel-list-calibration',
  LIST_DISPLAY = 'btn-submodel-list-display',
  ADD = 'btn-submodel-add',
  EDIT = 'btn-submodel-edit',
  DELETE = 'btn-submodel-delete',

  INPUT_DEFINITION_LIST = 'btn-input-definition-list',
  INPUT_DEFINITION_ADD = 'btn-input-definition-add',
  INPUT_DEFINITION_EDIT = 'btn-input-definition-edit',
  INPUT_DEFINITION_EDITOR = 'btn-input-definition-editor',
  INPUT_DEFINITION_EXPORT = 'btn-input-definition-export',
  INPUT_DEFINITION_DELETE = 'btn-input-definition-delete',

  SCENARIO_LIST = 'btn-instance-list',
  SCENARIO_ADD = 'btn-instance-add',
  SCENARIO_EDIT = 'btn-instance-edit',
  SCENARIO_EDITOR = 'btn-instance-editor',
  SCENARIO_DETAIL = 'btn-instance-detail',
  SCENARIO_EXECUTE = 'btn-instance-execute',
  SCENARIO_CANCEL = 'btn-instance-cancel',
  SCENARIO_DELETE = 'btn-instance-delete',
  SCENARIO_DOWNLOAD_OUTPUT = 'btn-instance-download-output',
  SCENARIO_PARAMETERS_EXPORT = 'btn-instance-export',
  SCENARIO_ACCEPT = 'btn-instance-accept',
  SCENARIO_UNACCEPT = 'btn-instance-unaccept',
  SCENARIO_SUPERSEDE = 'btn-instance-supersede',
}

export enum VISUALIZATION_ACTION_MENU_IDS {
  CREATE_VISUALIZATION = 'btn-visualization-create',
  GROUP_INSTANCES = 'btn-visualization-group-instances',
  EDIT_LABEL = 'btn-visualization-edit-label',
  TEST_VISUALIZATION_RESULTS = 'btn-visualization-test-results',
  SAVE_ANALYSIS = 'btn-visualization-save-analysis',
  SAVE_PROGRESS = 'btn-visualization-save-progress',
  FRESH_START = 'btn-visualization-fresh-start',
  SHARE = 'btn-visualization-share',
  EXPORT_RESULTS_PPT = 'btn-visualization-export-results-ppt',
  EXPORT_RESULTS_PDF = 'btn-visualization-export-results-pdf',
  EXPORT_RESULTS_XLSX = 'btn-visualization-export-results-xlsx',
}

export enum USER_ACTION_MENU_IDS {
  EDIT = 'btn-sidebar-edit',
  ACTIVATE = 'btn-sidebar-activate',
  DEACTIVATE = 'btn-sidebar-deactivate',
  EMAIL = 'btn-sidebar-email',
  CHAT = 'btn-sidebar-chat',
}

export enum MANAGE_METADATA_MENU_IDS {
  ADD = 'btn-sidebar-add',
  EDIT = 'btn-sidebar-edit',
  DELETE = 'btn-sidebar-delete',
  RETIRE = 'btn-sidebar-retire',
}

export enum SCENARIO_ACTION_MENU_IDS {
  VIEW = 'btn-scenario-view',
  EDIT = 'btn-scenario-edit',
  CLONE = 'btn-scenario-clone',
  EXECUTE = 'btn-scenario-execute',
  CANCEL = 'btn-scenario-cancel',
  DOWNLOAD = 'btn-scenario-results',
  DELETE = 'btn-scenario-delete',
}

export enum MODEL_IDS {
  EXCEL_RETIRED_ROOT = '1',
  EXCEL_PUBLISHED = '2',
  EXCEL_DRAFT = '3',
  EXCEL_RETIRED = '4',
  MATHEMATICA_RETIRED_ROOT = '11',
  MATHEMATICA_PUBLISHED = '12',
  MATHEMATICA_DRAFT = '13',
  MATHEMATICA_RETIRED = '14',
  PYTHON_PUBLISHED_ROOT = '21',
  SUBMODEL_ID = 10,
}

//for MODEL_IDS.MATHEMATICA_PUBLISHED
export enum SCENARIO_IDS {
  DRAFT_JSON_TEMPLATE = 2,
  DRAFT_JSON,
  INQUEUE,
  INPROCESS,
  SUSPENDED,
  COMPLETED,
  FAILED,
  CANCELLED,
  ACCEPTED_JSON,
  ACCEPTED_JSONTEMPLATE,
}
