import {
  CreateModelItemRequest,
  CreateParameterItemRequest,
  UpdateModelItemRequest,
  ItemsApi,
  ModelsApi,
  parseDate,
  UpdateFileTagRequest,
} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';
import {flattenTree} from './utils';

const modelApi = new ModelsApi(configuration);
const itemApi = new ItemsApi(configuration);

export const requestFileTree = (modelUuid: string, filterTags?: Array<string>, excludeTags?: Array<string>) => {
  const params = {
    modelUuid,
    ...(filterTags && filterTags.length > 0 ? {filterTags} : {}),
    ...(excludeTags && excludeTags.length > 0 ? {excludeTags} : {}),
  };

  return modelApi
    .getModelItemsTreeAudited(params)
    .then(flattenTree)
    .then((tree) => {
      return tree.map((f) =>
        // @ts-ignore
        f.updatedOn && !(f.updatedOn instanceof Date) ? {...f, updatedOn: parseDate(f.updatedOn)} : f,
      );
    });
};

export const requestFileTreeTH = (modelUuid: string) =>
  modelApi.getModelItemsTreeAuditedTH({modelUuid}).then((tree) => {
    return tree;
  });

export const requestUploadFile = (data: CreateModelItemRequest) => itemApi.createModelItem(data);

export const requestUploadParameterFile = (data: CreateParameterItemRequest) => itemApi.createParameterItem(data);

export const requestDeleteFile = (modelUuid: string, itemUuid: string) =>
  itemApi.deleteModelItem({modelUuid, itemUuid});

export const requestUpdateFileTag = (data: UpdateFileTagRequest) => itemApi.updateFileTag(data);

export const requestUpdateFile = (data: UpdateModelItemRequest) => itemApi.updateModelItem(data);

export const requestModelItemDownload = (modelUuid: string, itemUuid: string) =>
  itemApi.getModelItemRaw({modelUuid, itemUuid});

export const requestModelHelpFileDownload = (modelUuid: string, itemUuid: string) =>
  itemApi.getModelHelpFileRaw({modelUuid, itemUuid});

export const requestModelItemHistory = (modelUuid: string, itemUuid: string) =>
  itemApi.getModelItemHistory({
    modelUuid,
    itemUuid,
  });

export const requestModelMarkChangeLog = (modelUuid: string, itemUuid: string) =>
  itemApi.markChangelog({modelUuid, itemUuid});

export const requestModelUnmarkChangeLog = (modelUuid: string, itemUuid: string) =>
  itemApi.unmarkChangelog({modelUuid, itemUuid});
