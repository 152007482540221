import React, {ReactNode, useState} from 'react';
import {styles} from './styles';
import clsx from 'clsx';
import {ModelDTO} from 'hemwb-api';
import WarningText from '../../../../core/WarningText/WarningText';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';
import {Tag32} from '@carbon/icons-react';
import FileManager from '../ModelEdit/ModelEditFiles/FileManager';
import {getModelEditFilesMode, ModelEditFilesMode} from '../ModelEdit/ModelEditFiles/utils';
import {ModelTreeNode} from '../../../../../store/modelFile';

export type ModelEditFileTypesProps = {
  model: ModelDTO;
  files?: ModelTreeNode[];
  onChange?: (updatedModel: ModelDTO, updatedFiles: ModelTreeNode[]) => void;
  onSubmit: (updatedModel: ModelDTO, updatedFiles: ModelTreeNode[]) => void;
  buttons: () => string | ReactNode;
  createFlow?: boolean;
  uploadFlow?: boolean;
  cloneFileTypeFlow?: boolean;
  unSelectAll?: boolean;
};

const ModelEditFileTypes: React.FC<ModelEditFileTypesProps> = ({model, files, unSelectAll, ...restProps}) => {
  const workingModel = {...model, metadata: {...model.metadata}};
  const [forcedMode, setForcedMode] = useState<ModelEditFilesMode>();
  const mode = forcedMode || getModelEditFilesMode(workingModel, files);
  return (
    <>
      <div className={clsx(styles.container)}>
        <WarningText text="You can update these file types in the new model entry anytime." />
        <br />
        <ActionHeader label="" Icon={<Tag32 />}>
          Add or Modify File Types
        </ActionHeader>

        <FileManager
          model={workingModel}
          files={files}
          onEdit={() => mode === ModelEditFilesMode.NONE && setForcedMode(ModelEditFilesMode.MLMP)}
          mode={mode}
          hideUploadFileButton={true}
          hideDeleteFileButton={true}
          filteredFiles={true}
          cloneFlow={true}
          {...restProps}
        />
      </div>
    </>
  );
};

export default ModelEditFileTypes;
